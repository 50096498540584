<template>
  <div class="about">
    <head-nav></head-nav>
    <about-img></about-img>
    <div class="about-box">
      <h3 class="about-box-title">
        <img
          :src="$t('about.title-5')"
          alt=""
          :style="this.$i18n.locale==='en'?'width:260px;height:auto;':''"
        >
      </h3>

      <div class="about-box-content">
        <div class="about-box-content-info">
          <div class="about-box-imgs">

            <div v-html="$t('about.about-introduce.list-1.content')">

            </div>
            <img
              :src="$t('about.about-introduce.list-1.img')"
              alt=""
            >

          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import HeadNav from './head-nav';
import AboutImg from './about-img';
import Footers from './footers';

export default {
  components: {
    HeadNav,
    AboutImg,
    Footers,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.about {
  background: #f8f8f8;

  .about-box {
    margin: 0 30px;
    padding-bottom: 90px;

    &-title {
      margin: 50px 0 30px 0;
      text-align: center;

      &-img {
        width: 340px;
        height: 68px;
      }

      img {
        height: 70px;
      }
    }

    &-content {
      font-size: 28px;
      line-height: 40px;

      &-info {
        width: 100%;
        margin-top: 40px;
        .about-box-imgs {
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
